import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "styled-components"

export const theme = {
  colors: {
    charcoal: "#2c2d2c",
    charcoal03: "rgba(44,45,44,0.3)",
    green: "#8ede63",
    greenLight: "#f5ffb6",
    greenLighter: "#f0fbea",
    greenDark: "#52b232",
    greenDarker: "#135434",
    mango: "#ffb72b",
    offWhite: "#fcffed",
    white: "#ffffff",
    red: "#d34407",
    redLight: "#faece6",
    blueLight: "#E5F0FA",
  },
  sizes: {
    h1: "44px",
    h2: "36px",
    h3: "28px",
    h4: "18px",
    p: "16px",
  },
}

theme.buttonTypes = {
  primary: {
    backgroundColor: theme.colors.green,
    color: theme.colors.greenLighter,
  },
  secondary: {
    backgroundColor: theme.colors.greenLight,
    color: theme.colors.greenLighter,
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme

Theme.propTypes = {
  children: PropTypes.array,
}
