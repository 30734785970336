import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ZapIcon from "../images/group-11@3x.png"
import { navigate } from "gatsby"

import Theme from "../styles/theme"
import { GlobalStyle } from "../GlobalStyle"
import { Container, Button, ButtonText, Text, ContainerText, TextUndeline } from './styles';
import "./layout.css"
import "./animate.css"

const Layout = ({ children }) => {
  const [cookieAccept, setCookieAccept] = useState(true)

  useEffect(()=>{
    if(typeof window !== "undefined"){
      setCookieAccept(!!localStorage.getItem('cookieAccept'));
    }
  },[]);

  return (
    <Theme>
      <GlobalStyle theme="green" />
      <main>{children}</main>

      {!cookieAccept && (
        <Container>
          <ContainerText>
            <Text>
              Nossa plataforma utiliza cookies para melhorar a sua experiência. Ao navegar no nosso site, você concorda e aceita que cookies serão armazenados no seu dispositivo. Para mais informações, consulte a nossa <TextUndeline onClick={()=> navigate('/privacy')}>Política de Privacidade</TextUndeline>.
            </Text>
          </ContainerText>

          <Button 
            onClick={() => {
              localStorage.setItem('cookieAccept', true);
              setTimeout(()=> setCookieAccept(true), 300);
            }}>
            <ButtonText>Entendi a concordo</ButtonText>
          </Button>
        </Container>
      )}
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
