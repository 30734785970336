import styled from 'styled-components';
import media from "styled-media-query"

export const Container = styled.div`
  width: 98%;
  background-color: ${props => props.theme.colors.greenDarker};
  position: fixed;
  bottom: 0px; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

export const ContainerText = styled.div`
  display: flex;
`;

export const Button = styled.div`
  padding: 15px 20px;
  background-color: ${props => props.theme.colors.green};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  min-width: 200px;

  ${media.lessThan("medium")`
    width: 100%;
    margin-top: 20px;
  `}
`;

export const ButtonText = styled.span`
  text-align: center;
  font-size: 14px;
  font-family: Poppins;
  color: ${({ theme }) => theme.colors.charcoal};
`;

export const Text = styled.span`
  font-size: 12px;
  font-family: Poppins;
  color: white;
  line-height: 24px;
  letter-spacing: 0.6px;
`;

export const TextUndeline = styled.span`
  font-size: 12px;
  font-family: Poppins;
  color: white;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-decoration: underline;
`;
