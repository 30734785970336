import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const GlobalStyle = createGlobalStyle`
  /* reset css  */
  ${reset}
  
  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


  /* css global */
  body {
        font-family: 'Poppins', sans-serif;
  }

  /* remove default border blue active */
  *:focus {
    outline: none !important;
  }

  a[href^="tel"]{
    color:inherit;
    text-decoration:none;
  }
  
  button{
    cursor: pointer;
  }
 
`
